var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar"},[(_vm.url !== null && _vm.url !== undefined && _vm.url !== '')?_c('img',{style:({
            'width': _vm.width,
            'height': _vm.height
        }),attrs:{"src":_vm.url,"alt":""}}):_c('div',{staticClass:"avatar__no-image",style:({
            'width': _vm.width,
            'height': _vm.height

        })}),_c('p',[_vm._v(_vm._s(_vm.title))])])}
var staticRenderFns = []

export { render, staticRenderFns }