<template>
    <div class="avatar">
        <img 
            v-if="url !== null && url !== undefined && url !== ''"
            :src="url" 
            alt=""
            :style="{
                'width': width,
                'height': height
            }"
    
        />

        <div 
            v-else
            class="avatar__no-image"
               :style="{
                'width': width,
                'height': height

            }"
        >
           
        </div>

         <p>{{title}}</p>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            default: '',
     
        },

        title: {
            type: String,
            required: true
        },

        width: {
            type: String,
            default: '100%'
        },

        height: {
            type: String,

        }

    }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}

.avatar {
    display: inline-block;

    &__no-image {
        display: block;
        background: $primary;
        padding: 20px;
        border-radius: 50%;
    }
}
</style>